<template>
  <div>
    <v-tooltip
      v-if="baselinesStore.baseline"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry(baselinesStore.baseline.id)"
        >
          <v-icon :color="section === 1 && $vuetify.theme.dark === false ? 'white' : 'secondary'">
            fal fa-pencil-alt
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Baseline</span>
    </v-tooltip>

    <v-tooltip
      v-else
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon :color="section === 1 && $vuetify.theme.dark === false ? 'white' : 'secondary'">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Baseline</span>
    </v-tooltip>

    <v-dialog
      v-model="show"
      persistent
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        height="800"
        flat
      >
        <v-card-title class="headline secondary--text">
          Baseline Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="secondary--text py-4">
          {{ `Project: ${usersStore.activeProject.short_name}` }}
        </v-card-subtitle>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-tabs
                    v-model="tab"
                    grow
                    show-arrows="mobile"
                    background-color="transparent"
                  
            >
                    <v-tab
                      :key="1"
                      :href="'#tab-1'"
                    >
                      Publications
                    </v-tab>
                    <v-tab
                :key="2"
                :href="'#tab-2'"
              >
                Funding Proposals
              </v-tab>

              <v-tabs-items v-model="tab">
                
                <!--Tab #1 Publications-->
                      <v-tab-item
                        :key="1"
                        value="tab-1"
                      >
                  <v-card
                    flat
                  
>
                    <v-container>
                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <span class="caption error--text">* Required Fields</span>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <span class="text-caption pb-2">{{ 'Provide the number of your publications that were published from ' + $_formatDate(projectsStore.project.date_start_baseline) + ' through ' + $_formatDate(projectsStore.project.date_end_baseline) + '. A publication can be a research journal article, conference proceeding, book chapter, or book, but not an abstract. Do not include any publications that are in press.' }}</span>
                        </v-col>
                      </v-row>

                      <v-row
dense
class="pb-0"
>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                        >
                        <span class="text-subtitle-2 font-weight-bold">1. Total Publications: *</span>
                        <ul class="text-subtitle-2">
                          <li>{{ 'Total number of publications between ' + $_formatDate(projectsStore.project.date_start_baseline) + ' and ' + $_formatDate(projectsStore.project.date_end_baseline) + '.' }}</li>
                        </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="1"
                        >
                         <v-text-field
                            v-model="pubsTotal"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="pubsTotal === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
dense
class="pt-2"
>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                        >
                        <span class="text-subtitle-2 font-weight-bold">2. T-2 Award Related Publications: *</span>
                        <ul class="text-subtitle-2">
                          <li>Number of your total publications that are related to your T-2 award research topic.</li>
                        </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="1"
                        >
                         <v-text-field
                            v-model="pubsT2"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="pubsT2 === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
dense
class="pt-2"
>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                        >
                        <span class="text-subtitle-2 font-weight-bold">3. Collaborative Publications: *</span>
                        <ul class="text-subtitle-2">
                          <li>Number of your total publications that are collaborative publications (defined as those with a co-author(s) from a lab other than your own).</li>
                        </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="1"
                        >
                         <v-text-field
                            v-model="pubsCollab"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="pubsCollab === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
dense
class="pt-2"
>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                        >
                        <span class="text-subtitle-2 font-weight-bold">4. Collaborative Publications with your T-2 Award Colleagues: *</span>
                        <ul class="text-subtitle-2">
                          <li>Number of your collaborative publications with co-author(s) who are on your T-2 award.</li>
                        </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="1"
                        >
                         <v-text-field
                            v-model="pubsCollabT2"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="pubsCollabT2 === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
dense
class="pt-2"
>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                        >
                        <span class="text-subtitle-2 font-weight-bold">5. Intra-Jurisdictional Collaborative Publications with your T-2 Award Colleagues: *</span>
                        <br>
                        <span class="text-subtitle-2">Number of your collaborative publications with co-author(s) who are on your T-2 award.</span>
                        <ul class="text-subtitle-2">
                          <li>co-author(s) who are on your T-2 award AND</li>
                          <li>co-author(s) whose home institution(s) are in your T-2 award jurisdiction.</li>
                        </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="1"
                        >
                         <v-text-field
                            v-model="pubsIntra"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="pubsIntra === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
dense
class="pt-2"
>
                        <v-col
                          cols="12"
                          sm="12"
                          md="10"
                        >
                        <span class="text-subtitle-2 font-weight-bold">6. Inter-Jurisdictional Collaborative Publications with your T-2 Award Colleagues: *</span>
                        <br>
                        <span class="text-subtitle-2">Number of your collaborative publications with co-author(s) who are on your T-2 award.</span>
                        <ul class="text-subtitle-2">
                          <li>co-author(s) who are on your T-2 award AND</li>
                          <li>co-author(s) whose home institutions(s) are in a T-2 award jurisdiction OTHER than your own.</li>
                        </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="1"
                        >
                         <v-text-field
                            v-model="pubsInter"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="pubsInter === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                       <v-divider />
                    </v-container>
                  
</v-card>
                
                      </v-tab-item>

                      <!--Tab #2 Funding Proposals-->
                      <v-tab-item
                  :key="2"
                  value="tab-2"
                >
                          <v-card
                              flat
                            
                  >
                    <v-container>
                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <span class="caption error--text">* Required Fields</span>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <span class="text-caption pb-4">{{ 'Provide the number of your proposals that were submitted from ' + $_formatDate(projectsStore.project.date_start_baseline) + ' through ' + $_formatDate(projectsStore.project.date_end_baseline) + ' and of those proposals, the number that were funded and the number that are pending. Note: Do NOT include your NSF EPSCoR RII T-2 application.' }}</span>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <span class="subtitle-1 font-weight-bold">All Proposals</span>
                        </v-col>

                        <v-col
                          class="d-flex align-center"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <span class="subtitle-1 font-weight-bold text-center">NSF Proposals</span>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          offset-md="6"
                        >
                          <span class="subtitle-2">Submitted</span>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <span class="subtitle-2">Funded</span>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <span class="subtitle-2">Pending</span>
                        </v-col>

                        <v-col
                          text-center
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <span class="subtitle-2">Submitted</span>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <span class="subtitle-2">Funded</span>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <span class="subtitle-2">Pending</span>
                        </v-col>
                      </v-row>

                      <v-divider class="pb-2" />

                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span class="subtitle-2 font-weight-bold">1. Total Proposals: *</span>
                          <ul class="subtitle-2">
                            <li>{{ 'Total number of proposals between ' + $_formatDate(projectsStore.project.date_start_baseline) + ' and ' + $_formatDate(projectsStore.project.date_end_baseline) + '.' }}</li>
                          </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsTotalAllSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsTotalAllSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsTotalAllFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsTotalAllFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsTotalAllPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsTotalAllPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsTotalNSFSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsTotalNSFSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsTotalNSFFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsTotalNSFFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsTotalNSFPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsTotalNSFPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
                        dense
                        class="pt-2"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span class="text-subtitle-2 font-weight-bold">2. T-2 Award Related Proposals: *</span>
                          <ul class="text-subtitle-2">
                            <li>Number of your total proposals that are related to your T-2 award research topic.</li>
                          </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsT2AllSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsT2AllSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsT2AllFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsT2AllFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsT2AllPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsT2AllPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsT2NSFSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsT2NSFSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsT2NSFFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsT2NSFFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsT2NSFPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsT2NSFPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
                        dense
                        class="pt-2"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span class="text-subtitle-2 font-weight-bold">3. Collaborative Proposals: *</span>
                          <ul class="text-subtitle-2">
                            <li>Number of your total proposals that are collaborative proposals (defined as those with a PI(s)/Co-PI(s) from a lab other than your own).</li>
                          </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabAllSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabAllSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabAllFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabAllFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabAllPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabAllPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabNSFSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabNSFSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabNSFFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabNSFFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabNSFPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabNSFPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
                        dense
                        class="pt-2"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span class="text-subtitle-2 font-weight-bold">4. Collaborative Proposals with your T-2 Award Colleagues: *</span>
                          <ul class="text-subtitle-2">
                            <li>Number of your collaborative proposals with PI(s)/Co-PI(s) who are on your T-2 award.</li>
                          </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabT2AllSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabT2AllSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabT2AllFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabT2AllFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabT2AllPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabT2AllPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabT2NSFSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabT2NSFSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabT2NSFFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabT2NSFFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsCollabT2NSFPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsCollabT2NSFPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
                        dense
                        class="pt-2"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span class="text-subtitle-2 font-weight-bold">5. Intra-Jurisdictional Collaborative Proposals with your T-2 Award Colleagues: *</span>
                          <br>
                          <span class="text-subtitle-2">Number of your collaborative proposals with PI(s)/Co-PI(s) who are on your T-2 award.</span>
                          <ul class="text-subtitle-2">
                            <li>PI(s)/Co-PI(s) who are on your T-2 award AND</li>
                            <li>PI(s)/Co-PI(s) whose home institution(s) are in your T-2 award jurisdiction.</li>
                          </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsIntraAllSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsIntraAllSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsIntraAllFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsIntraAllFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsIntraAllPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsIntraAllPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsIntraNSFSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsIntraNSFSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsIntraNSFFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsIntraNSFFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsIntraNSFPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsIntraNSFPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row
                        dense
                        class="pt-2"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <span class="text-subtitle-2 font-weight-bold">6. Inter-Jurisdictional Collaborative Proposals with your T-2 Award Colleagues: *</span>
                          <br>
                          <span class="text-subtitle-2">Number of your collaborative proposals with PI(s)/Co-PI(s) who are on your T-2 award.</span>
                          <ul class="text-subtitle-2">
                            <li>PI(s)/Co-PI(s) who are on your T-2 award AND</li>
                            <li>PI(s)/Co-PI(s) whose home institution(s) are in a T-2 award jurisdiction other than your own.</li>
                          </ul>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsInterAllSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsInterAllSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsInterAllFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsInterAllFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsInterAllPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsInterAllPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsInterNSFSub"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsInterNSFSub === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsInterNSFFund"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsInterNSFFund === 0 ? [] : rules.not_empty"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-text-field
                            v-model="proposalsInterNSFPend"
                            placeholder=" "
                            outlined
                            dense
                            required
                            :rules="proposalsInterNSFPend === 0 ? [] : rules.not_empty"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                          
                  </v-card>
                      
                </v-tab-item>
              </v-tabs-items>
                
            </v-tabs>
          </v-form>
        </v-card-text>

        <v-card-actions class="mx-4 pb-4">
          <v-row dense>
            <v-col
              v-if="tab === 'tab-2'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                color="red"
                dark
                outlined
                @click="previousTab()"
              >
                <v-icon
                  small
                  left
                >
                  fal fa-arrow-left
                </v-icon>Back
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col
              v-if="tab === 'tab-2'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                color="primary"
                @click="saveEntry()"
              >
                <v-icon
                  small
                  left
                >
                  fal fa-check
                </v-icon>Submit
              </v-btn>
            </v-col>

            <v-col
              v-if="tab === 'tab-1'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                class="green darken-2"
                dark
                @click="nextTab()"
              >
                Next
                <v-icon
                  small
                  right
                >
                  fal fa-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'baselinesStore/getEntryField',
  mutationType: 'baselinesStore/updateEntryField'
});

export default {
  name: 'BaselineEntry',
  mixins: [mixin],
  props: {
    section: Number,
    readonly: Boolean
  },
  data() {
	  return {
      rules: {
        not_empty: [
          v => !!v || ''
        ]
      }
	  };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      baselinesStore: state => state.baselinesStore,
      projectsStore: state => state.projectsStore
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',

      'tab',

      'id_user',
      'id_project',
      'pubsTotal',
      'pubsT2',
      'pubsCollab',
      'pubsCollabT2',
      'pubsIntra',
      'pubsInter',
      'proposalsTotalAllSub',
      'proposalsTotalAllFund',
      'proposalsTotalAllPend',
      'proposalsTotalNSFSub',
      'proposalsTotalNSFFund',
      'proposalsTotalNSFPend',
      'proposalsT2AllSub',
      'proposalsT2AllFund',
      'proposalsT2AllPend',
      'proposalsT2NSFSub',
      'proposalsT2NSFFund',
      'proposalsT2NSFPend',
      'proposalsCollabAllSub',
      'proposalsCollabAllFund',
      'proposalsCollabAllPend',
      'proposalsCollabNSFSub',
      'proposalsCollabNSFFund',
      'proposalsCollabNSFPend',
      'proposalsCollabT2AllSub',
      'proposalsCollabT2AllFund',
      'proposalsCollabT2AllPend',
      'proposalsCollabT2NSFSub',
      'proposalsCollabT2NSFFund',
      'proposalsCollabT2NSFPend',
      'proposalsIntraAllSub',
      'proposalsIntraAllFund',
      'proposalsIntraAllPend',
      'proposalsIntraNSFSub',
      'proposalsIntraNSFFund',
      'proposalsIntraNSFPend',
      'proposalsInterAllSub',
      'proposalsInterAllFund',
      'proposalsInterAllPend',
      'proposalsInterNSFSub',
      'proposalsInterNSFFund',
      'proposalsInterNSFPend',
    ])
  },
  methods: {
    async newEntry() {
      this.$store.dispatch('baselinesStore/resetEntry');

      const activeUser = this.$store.getters['usersStore/getActiveUser'];
      this.id_user = activeUser.id;

      const activeProject = this.$store.getters['usersStore/getActiveProject'];
      this.id_project = activeProject.id;
      await this.$store.dispatch('projectsStore/read', this.id_project);
      this.show = true;
    },
    async openEntry(id) {
      const activeProject = this.$store.getters['usersStore/getActiveProject'];
      this.id_project = activeProject.id;
      await this.$store.dispatch('projectsStore/read', this.id_project);
		  await this.$store.dispatch('baselinesStore/entry', id);
    },
    cancelEntry() {
      this.show = false;
    },
    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['baselinesStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('baselinesStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('baselinesStore/update', entry);
        }
        this.cancelEntry();
      }
      else
        this.tab = 'tab-1';
    },
    nextTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);

      //if (tabNum === 1 && !this.$refs.entryForm.validate()) return;

      tabNum++;

      this.tab = `tab-${tabNum}`;
    },

    previousTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);
      tabNum--;

      this.tab = `tab-${tabNum}`;
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
