<template>
  <v-col
    v-if="progressStore.progress !== true"
    cols="12"
    sm="12"
  >
    <v-toolbar
      color="transparent"
      dense
      flat
    >
      <v-toolbar-title>
        My Baseline
      </v-toolbar-title>

      <v-spacer />

      <baseline />
    </v-toolbar>

    <v-container 
      v-if="baselinesStore.baseline" 
      fluid
    >
      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <v-card flat>
            <v-card-title>Publications</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template #default>
                  <tbody>
                    <tr>
                      <td>Total Publications</td>
                      <td>{{ baselinesStore.baseline.pubsTotal }}</td>
                    </tr>
                    <tr>
                      <td>T-2 Award Related Publications</td>
                      <td>{{ baselinesStore.baseline.pubsT2 }}</td>
                    </tr>
                    <tr>
                      <td>Collaborative Publications</td>
                      <td>{{ baselinesStore.baseline.pubsCollab }}</td>
                    </tr>
                    <tr>
                      <td>Collaborative Publications with your T-2 Award Colleagues</td>
                      <td>{{ baselinesStore.baseline.pubsCollabT2 }}</td>
                    </tr>
                    <tr>
                      <td>Intra-Jurisdictional Collaborative Publications with your T-2 Award Colleagues</td>
                      <td>{{ baselinesStore.baseline.pubsIntra }}</td>
                    </tr>
                    <tr>
                      <td>Inter-Jurisdictional Collaborative Publications with your T-2 Award Colleagues</td>
                      <td>{{ baselinesStore.baseline.pubsInter }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <v-card flat>
            <v-card-title>Funding Proposals</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template #default>
                  <thead>
                    <tr>
                      <th />
                      <th 
                        colspan="3" 
                        class="text-center"
                      >
                        All Proposals
                      </th>
                      <th 
                        colspan="3" 
                        class="text-center"
                      >
                        NSF Proposals
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th class="text-left">
                        Submitted
                      </th>
                      <th class="text-left">
                        Funded
                      </th>
                      <th class="text-left">
                        Pending
                      </th>
                      <th class="text-left">
                        Submitted
                      </th>
                      <th class="text-left">
                        Funded
                      </th>
                      <th class="text-left">
                        Pending
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Proposals</td>
                      <td>{{ baselinesStore.baseline.proposalsTotalAllSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsTotalAllFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsTotalAllPend }}</td>
                      <td>{{ baselinesStore.baseline.proposalsTotalNSFSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsTotalNSFFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsTotalNSFPend }}</td>
                    </tr>
                    <tr>
                      <td>T-2 Award Related Proposals</td>
                      <td>{{ baselinesStore.baseline.proposalsT2AllSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsT2AllFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsT2AllPend }}</td>
                      <td>{{ baselinesStore.baseline.proposalsT2NSFSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsT2NSFFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsT2NSFPend }}</td>
                    </tr>
                    <tr>
                      <td>Collaborative Proposals</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabAllSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabAllFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabAllPend }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabNSFSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabNSFFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabNSFPend }}</td>
                    </tr>
                    <tr>
                      <td>Collaborative Proposals with your T-2 Award Colleagues</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabT2AllSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabT2AllFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabT2AllPend }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabT2NSFSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabT2NSFFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsCollabT2NSFPend }}</td>
                    </tr>
                    <tr>
                      <td>Intra-Jurisdictional Collaborative Proposals with your T-2 Award Colleagues</td>
                      <td>{{ baselinesStore.baseline.proposalsIntraAllSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsIntraAllFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsIntraAllPend }}</td>
                      <td>{{ baselinesStore.baseline.proposalsIntraNSFSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsIntraNSFFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsIntraNSFPend }}</td>
                    </tr>
                    <tr>
                      <td>Inter-Jurisdictional Collaborative Proposals with your T-2 Award Colleagues</td>
                      <td>{{ baselinesStore.baseline.proposalsInterAllSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsInterAllFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsInterAllPend }}</td>
                      <td>{{ baselinesStore.baseline.proposalsInterNSFSub }}</td>
                      <td>{{ baselinesStore.baseline.proposalsInterNSFFund }}</td>
                      <td>{{ baselinesStore.baseline.proposalsInterNSFPend }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container 
      v-else
      fluid
    >
      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <v-card
            height="300"
            flat
          >
            <v-container 
              fill-height 
              fluid
            >
              <v-row
                align="center"
                justify="center"
              >
                No Baseline Data
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TextDisplay from '@/components/TextDisplay';
import Baseline from '@/components/Baseline';


export default {
  name: 'MyBaseline',
  components: {
    TextDisplay,
    Baseline
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      baselinesStore: state => state.baselinesStore,
      usersStore: state => state.usersStore,
      progressStore: state => state.progressStore
    }),
  },
  async mounted() {
    this.$store.dispatch('progressStore/set', true);

    const activeUser = await this.$store.getters['usersStore/getActiveUser'];
    const activeProject = await this.$store.getters['usersStore/getActiveProject'];

    const data = {
      id_user: activeUser.id,
      id_project: activeProject.id
    };

    await this.$store.dispatch('baselinesStore/myBaseline', data);

    this.$store.dispatch('progressStore/set', false);
  }
};
</script>

<style scoped>
.transparent-background {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>